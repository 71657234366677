import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/website.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/',
  //   name: '',
  //   component: () => import('')
  // }
]

const router = new VueRouter({
  routes
})

export default router