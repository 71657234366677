<template>
  <!-- 官网  -->
  <div class="website w">
    <div class="header w">
      <div class="df alc h son">
        <img src="@/assets/img/logogw.png" alt="" />
        <div class="son_right df alc h">
          <div class="border">首页</div>
          <el-dropdown @visible-change="visible">
            <div class="cup" style="padding: 0">
              <span class="c">产品中心 </span>
              <i
                :class="icon ? 'hover_' : 'hover'"
                style="color: #e5e5e5; margin-left: 15px"
                class="el-icon-arrow-up"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="open('http://www.wms.orangerp.cn')">O-WMS系统</span>
              </el-dropdown-item>

              <el-dropdown-item>
                <span @click="open('http://www.oms.orangerp.cn')">W-OMS系统</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="w bgc">
      <div class="text por">
        <p class="p-1">专注卖家-第三方仓储高效合作的海外仓管理系统</p>
        <p class="p-2">橘子O-WMS 海外仓系统</p>
        <div class="round"></div>
      </div>
      <p class="p-3">简单易操作 数据资产化 财务数据报表 订单&仓储数据精细化</p>
      <div class="free df- cup" @click="open('http://www.wms.orangerp.cn')">
        >立即试用
      </div>
    </div>
    <div class="o-wms df-">
      <p class="O-">橘子O-WMS 海外仓系统</p>
      <div class="blue"></div>
      <p class="Overseas">O-WMS Overseas warehouse system</p>
      <div class="focus">
        专注卖家-第三方仓储高效合作的海外仓管理系统，帮助第三方仓储快速搭建仓储对外服务，支持多仓管理，对客户对接，多维度数据跟踪，将企业数据资产化，实现跨域全面信息高效运转。系统支持一件代发，中转仓，调拨，订单定位最近发货原则，退货入库，店铺授权，数据自动推送，异常全程跟踪，减少资产流失。达到第三方仓储-企业跨域合作生态平衡化。
      </div>
    </div>
    <div class="o-wms df-">
      <p class="O-">业务场景</p>
      <div class="blue"></div>
      <p class="Overseas">
        B &nbsp;&nbsp;u &nbsp;&nbsp;s &nbsp;&nbsp;i &nbsp;&nbsp;n &nbsp;&nbsp;e
        &nbsp;&nbsp;s &nbsp;&nbsp;s &nbsp;&nbsp;s &nbsp;&nbsp;c &nbsp;&nbsp;e
        &nbsp;&nbsp;n &nbsp;&nbsp;a &nbsp;&nbsp;r &nbsp;&nbsp;i &nbsp;&nbsp; o
      </p>
      <div class="card w df">
        <div class="df-">
          <img src="@/assets/img/008.png" alt="" />
          <p>多海外仓管理</p>
        </div>
        <div class="df-">
          <img src="@/assets/img/007.png" alt="" />
          <p>一件代发</p>
        </div>
        <div class="df-">
          <img src="@/assets/img/006.png" alt="" />
          <p>中转仓</p>
        </div>
        <div class="df-">
          <img src="@/assets/img/005.png" alt="" />
          <p>退货换标</p>
        </div>
      </div>
      <div class="card w df" style="margin-top: 34px">
        <div class="df-">
          <img src="@/assets/img/004.png" alt="" />
          <p>订单推送</p>
        </div>
        <div class="df-">
          <img src="@/assets/img/003.png" alt="" />
          <p>财务报表</p>
        </div>
        <div class="df-">
          <img src="@/assets/img/002.png" alt="" />
          <p>数据资产化</p>
        </div>
        <div class="df-">
          <img src="@/assets/img/001.png" alt="" />
          <p>异常推送</p>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="w" style="background-color: #f6f7fb">
      <div
        class="o-wms df-"
        style="background-color: #f6f7fb; padding: 100px 0"
      >
        <p class="O-">系统功能</p>
        <div class="blue"></div>
        <p class="Overseas">
          高 &nbsp;效&nbsp; 精&nbsp; 细&nbsp; 化&nbsp; 全 &nbsp;流&nbsp;
          程&nbsp; 数&nbsp; 据&nbsp; 跟 &nbsp;踪&nbsp; 管&nbsp; 理
        </p>
        <div class="function w df">
          <div class="left">
            <div class="top">
              <p class="top-1">仓储管理</p>
              <p class="top-2">仓库全流程条码化管理，PDA操作，数据精准流转</p>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">入库管理</p>
                <p class="p_2">客户货件PDA扫码入库，数据自动同步</p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">出库管理</p>
                <p class="p_2">
                  一件代发，订单推送直发，换标出库，位置匹配出库
                </p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">PDA操作</p>
                <p class="p_2">
                  客户拣货单分类处理，PDA扫码拣货，PDA面单扫码出库，订单位置精准定位
                </p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">库存管理</p>
                <p class="p_2">盘点指令，调拨，库位管理</p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">异常跟踪</p>
                <p class="p_2">
                  库存问题，订单逾期，财务报表，库存冗余，智能补货，放大化满足用户需求，
                </p>
                <p class="p_2">保证企业资产安全。</p>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="@/assets/img/xtgn.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="w">
      <div class="o-wms df-" style="padding-bottom: 20px; margin-top: 0">
        <div class="function w df" style="background-color: #ffffff">
          <div class="right">
            <img src="@/assets/img/ddxt.png" alt="" />
          </div>
          <div class="left" style="padding: 80px; 0 0 100px    ">
            <div class="top">
              <p class="top-1">订单系统</p>
              <p class="top-2">订单数据安全性，时效性，生命周期跟踪</p>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">订单推送</p>
                <p class="p_2">
                  店铺授权，设置规则，订单数据自动推送至海外仓內流转
                </p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">手动创建</p>
                <p class="p_2">也可直接手动创建订单推送至海外仓內流转</p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">订单跟踪</p>
                <p class="p_2">
                  在订单生命周期内，对订单进行跟踪，异常及时推送处理
                </p>
              </div>
            </div>
            <div class="df management">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">订单资产化</p>
                <p class="p_2">
                  订单数据安全性，将客户订单数据资产化，进行封装保护
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w" style="background-color: #f6f7fb">
      <div
        class="o-wms df-"
        style="padding-bottom: 100px; margin-top: 0; background-color: #f6f7fb"
      >
        <div class="function w df" style="background-color: #f6f7fb">
          <div class="left">
            <div class="top">
              <p class="top-1">数据报表</p>
              <p class="top-2">
                将数据维度分析，形成决策指令，帮助用户快速做精准化运营
              </p>
            </div>
            <div class="df management mt">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">客户数据分析</p>
              </div>
            </div>
            <div class="df management mt">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">仓储运转分析</p>
              </div>
            </div>
            <div class="df management mt">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">库存分析</p>
              </div>
            </div>
            <div class="df management mt">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">费用分析</p>
              </div>
            </div>
            <div class="df management mt">
              <div class="blue_round"></div>
              <div class="">
                <p class="p_1">订单发仓分析</p>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="@/assets/img/sjbb.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="bottom-text w df">
      <div>
        <p class="one">关于我们</p>
        <p @click="open_click">橘子|海外仓</p>
      </div>
      <div>
        <p class="one">服务系统</p>
        <p @click="open('http://www.wms.orangerp.cn')">>O-WMS系统</p>
        <p @click="open('http://www.oms.orangerp.cn')">>W-OMS系统</p>
      </div>
      <div>
        <p class="one">服务系统</p>
        <img src="@/assets/img/rwm.png" alt="" />
      </div>
    </div>
    <div class="df- w black alc">
      @2020 深圳市汇橘子信息科技有限公司 粤ICP备2024276549号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: false,
    };
  },
  created() {},
  methods: {
    open(name) {
      window.open(name);
    },
    // 橘子|海外仓
    open_click() {
      // window.open('');
    },
    // 下拉框出现/隐藏时触发
    visible(value) {
      this.icon = value;
      // if (value) {
      //   this.icon = "el-icon-arrow-down";
      // } else {
      //   this.icon = "el-icon-arrow-up";
      // }
    },
  },
};
</script>

<style lang='less' scoped>
.website {
  .header {
    height: 80px;
    background-color: #172541;
    .son {
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
      .son_right {
        font-weight: bold;
        font-size: 14px;
        color: #ffffff;
        div {
          padding-bottom: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .c {
          margin-left: 72px;
          color: #ffffff;
        }
        .border {
          border-bottom: 2px solid #ffffff;
        }

        .hover {
          transform: rotate(0deg);
          transition: all 0.4s;
        }
        .hover_ {
          transform: rotate(180deg);
          transition: all 0.4s;
        }
      }
    }
  }
  .bgc {
    height: 800px;
    background: url("../assets/img/bgcgw.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-left: 19%;
    padding-top: 13%;
    .text {
      padding-left: 35px;
      .p-1 {
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        font-size: 28px;
      }
      .p-2 {
        color: #ffffff;
        font-weight: bold;
        font-size: 60px;
        margin-top: 20px;
      }
      .round {
        width: 10px;
        height: 119px;
        background: #ffffff;
        border-radius: 211px;
        position: absolute;
        left: 0;
        top: -8px;
      }
    }
    .p-3 {
      color: rgba(255, 255, 255, 0.9);
      font-weight: 400;
      font-size: 26px;
      margin-top: 20px;
    }
    .free {
      width: 180px;
      height: 52px;
      background: #4384ec;
      box-shadow: 0px 3px 6px rgba(7, 25, 41, 0.2);
      color: #fff;
      border-radius: 27px;
      font-size: 22px;
      font-weight: bold;
      margin-top: 82px;
    }
    .free:hover {
      transform: translate(0px, -2px);
      transition: all 0.1s;
    }
  }
  .o-wms {
    flex-direction: column;
    background-color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    margin-top: 95px;
    .O- {
      font-size: 32px;
      font-weight: bold;
      color: #201c32;
    }
    .blue {
      width: 68px;
      height: 2px;
      background: linear-gradient(90deg, #4384ec 0%, #4bd0ed 100%);
      margin-top: 15px;
    }
    .Overseas {
      font-size: 12px;
      color: #999999;
      font-weight: 400;
      margin-top: 15px;
    }
    .focus {
      font-weight: bold;
      line-height: 36px;
      font-size: 14px;
      color: #333333;
      margin-top: 60px;
      text-indent: 20px;
    }
  }
  .card {
    background-color: #fff;
    margin-top: 68px;
    justify-content: space-between;
    div {
      flex-direction: column;
      img {
        width: 154px;
        height: 154px;
      }

      p {
        color: #333333;
        font-weight: 800;
        font-size: 20px;
        margin-top: 14px;
      }
    }
    div:hover {
      transform: translate(0px, -10px);
      transition: all 0.5s;
      cursor: pointer;
    }
  }
  .function {
    background-color: #f6f7fb;
    justify-content: space-between;
    .left {
      width: 50%;
      .top {
        margin-top: 100px;
        .top-1 {
          font-weight: bold;
          color: #201c32;
          font-size: 28px;
        }
        .top-2 {
          font-weight: 500;
          font-size: 14px;
          color: #666666;
          margin-top: 15px;
          margin-bottom: 34px;
        }
      }
      .management {
        margin-top: 24px;
        .blue_round {
          width: 12px;
          height: 12px;
          background: linear-gradient(180deg, #4384ec 0%, #4bd0ed 100%);
          border-radius: 50%;
          margin-right: 10px;
          margin-top: 2px;
        }
        .p_1 {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
        }
        .p_2 {
          font-size: 14px;
          font-weight: 500;
          color: #808080;
          margin-top: 10px;
        }
      }
    }
    .right {
      margin-top: 100px;
      flex: 1;
      img {
        width: 100%;
      }
      img:hover {
        transform: translate(0px, -15px);
        transition: all 0.5s;
        cursor: pointer;
      }
    }
  }
  .mt {
    margin-top: 50px !important;
  }
  .bottom-text {
    height: 200px;
    background-color: #232834;
    justify-content: space-around;
    padding-top: 36px;
    div {
      p {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #a7b7c6;
        margin-bottom: 14px;
      }
      .one {
        width: 64px;
        font-size: 16px;
        color: #ffffff;
        cursor: none;
      }
    }
  }
  .black {
    height: 53px;
    color: #fff;
    font-size: 12px;
    background-color: #131619;
  }
}
</style>